import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Emergenza from '../components/emergenza'
import PrenotaVisitaTabs from '../components/prenota-visita-tabs'
import DoveSiamo from '../components/dove-siamo'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"


class PrenotaVisita extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Prenota visita"}];
    const data = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const staff = data.staff || []; 
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "PRENOTA UNA VISITA",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Hero data={datahero}/>
        <PrenotaVisitaTabs  data="none"/>
        <div className="container">
          <Helmet title="Prenota una visita"
             bodyAttributes={
              { class :'has-hero'}
            }
          />
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <h1>Prenota una visita</h1>
              {!!data.descrizione && 
                <div dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                
                </div>
              }
              {staff.length>0 &&
                <div>
                  <h2>Staff</h2>
                  <div className="row">
                    {staff.map((item,index) => {
                      let thumb = "/images/staff-placeholder.png";
                      if (item.immagine) {
                        thumb = item.immagineLista.file.url  
                      }
                      return (
                        <div key={index} className="col-sm-6 col-lg-3">
                          <div className="staff-list staff-list--bottom">
                            <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name"><img src={thumb} alt={`${item.cognome} ${item.nome}`} className="staff-list__img" /></Link>
                            <div className="staff-list__detail">
                              <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name">{item.nome} {item.cognome}</Link>
                              <div className="staff-list__role">{item.ruolo}</div>
                            </div>
                          </div>
                        </div> 
                      )
                      })
                    }
                  </div>
                </div>
              }
            </div>
            <div className="col-md-4">
              <DoveSiamo/>
              <Emergenza/>
              {!!data.attachment &&
                <DocumentiUtili data={data.attachment}/>
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default PrenotaVisita;

export const query = graphql`
query PrenotaQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "5V9F2AOYwMww4sg4gOGEei"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    staff {
      nome
      cognome
      friendlyUrl
      ruolo
      immagineLista {
        file {
          url
        }
      }
      immagine {
        file {
          url
        }
      }
    }
  }
  hero: file(relativePath: {eq: "header_uccello.jpg"}) {
    publicURL
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`